<template>
  <div :class="holyGrailClasses">
    <div
      v-if="hasSlot('navBreadcrumb') || breadcrumb"
      :class="navBreadcrumbClasses">
      <slot name="navBreadcrumb">
        <BaseBreadcrumb :title="title" @back="$emit('back')"></BaseBreadcrumb>
      </slot>
    </div>

    <div
      v-if="hasSlot('header') || (title && header)"
      :class="navBreadcrumbClasses">
      <slot name="header">
        <p v-if="title" class="h-full text-lg font-anomaliaBold">
          {{ title }}
        </p>
      </slot>
    </div>
    <div
      v-if="hasSlot('right') || hasSlot('main')"
      ref="container"
      class="relative"
      :class="containerClasses">
      <BaseScrollbar
        v-if="hasSlot('right')"
        :scroll-y-margin-offset="marginBottom ? 80 : 0"
        :class="rightClasses"
        :style="{ height: `${heightMargin}px` }">
        <div class="flex flex-col gap-5">
          <slot name="right"></slot>
          <div v-if="marginBottom" class="pb-20"></div>
        </div>
      </BaseScrollbar>

      <BaseScrollbar
        :scroll-y-margin-offset="marginBottom ? 80 : 0"
        v-bind="mainScrollbar"
        :class="mainClasses"
        :style="{ height: `${heightMargin}px` }">
        <div class="flex flex-col gap-5">
          <slot v-if="hasSlot('main')" name="main"></slot>
          <div v-else class="h-full">
            <h1>Page doesn't exist</h1>
          </div>
          <div v-if="marginBottom" class="pb-20"></div>
        </div>
      </BaseScrollbar>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, PropType, ref, toRefs, useSlots } from 'vue';
import { HolyGrailOffset } from '@/theme/layouts/HolyGrail/types';
import { useHolyGrailClasses } from '@/theme/layouts/HolyGrail/composables/useHolyGrailClasses';
import BaseBreadcrumb from '@/components/Breadcrumb/BaseBreadcrumb.vue';
import { BaseScrollbar } from '@/components';
import { useEventListener } from '@vueuse/core';
const slots = useSlots();
const hasSlot = name => !!slots[name];

const props = defineProps({
  stretch: {
    type: Boolean,
    default: false,
  },
  maxWidth: {
    type: Boolean,
    default: true,
  },
  header: {
    type: Boolean,
    default: true,
  },
  flip: {
    type: Boolean,
    default: false,
  },
  marginBottom: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  breadcrumb: {
    type: Boolean,
    default: false,
  },
  offset: {
    type: String as PropType<HolyGrailOffset>,
    default: 'center',
  },
  mainScrollbar: {
    type: Object,
    default: null,
  },
});

defineEmits(['back']);
const container = ref();
const heightMargin = ref(0);

const calcHeight = () => {
  let rect = container.value?.getBoundingClientRect();
  const height = window.innerHeight - rect?.top;
  heightMargin.value = height;
};

onMounted(() => {
  useEventListener(window, 'resize', () => {
    calcHeight();
  });
  nextTick(() => {
    calcHeight();
  });
});

const {
  mainClasses,
  rightClasses,
  navBreadcrumbClasses,
  containerClasses,
  holyGrailClasses,
} = useHolyGrailClasses(toRefs(props), {
  hasRight: hasSlot('right'),
  hasMain: hasSlot('main'),
});
</script>
