<template>
  <div class="flex gap-2 items-center font-bold">
    <ArrowRight
      class="stroke-primary h-4 cursor-pointer -m-[0.4rem]"
      @click="$emit('back')"/>

    <RouterLink :to="backTo" class="text-primary">
      {{ backText }}
    </RouterLink>
    <h6 v-if="title">/</h6>
    <span v-for="(item, index) in breadcrumbs" :key="index" class="flex gap-2">
      <RouterLink :to="item.path" class="flex gap-2">
        {{ item.title }}
      </RouterLink>
      <h6>/</h6>
    </span>
    <h6 v-if="title">{{ title }}</h6>
  </div>
</template>
<script lang="ts" setup>
import { computed } from 'vue';
import { ArrowRight } from 'lucide-vue-next';
// import { useBreadcrumbs } from './composables/useBreadcrumbs';
// import { toRefs } from 'vue';
// import { useBreadcrumbClasses } from '@/components/Breadcrumb/composables/useBreadcrumbClasses';

interface Breadcrumb {
  title: string;
  path: string;
}

const props = defineProps({
  solid: {
    type: Boolean,
    default: false,
  },
  backText: {
    type: String,
    default: 'המרחב שלי',
  },
  backTo: {
    type: String,
    default: '/',
  },
  title: {
    type: String,
    default: '',
  },
  data: {
    type: Array<Breadcrumb>,
    default: () => [],
  },
});

const breadcrumbs = computed(() => props.data);

defineEmits(['back']);
</script>
