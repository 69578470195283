<template>
  <HolyGrail v-bind="props" @back="$router.back()">
    <template v-if="hasSlot('navBreadcrumb')" #navBreadcrumb>
      <slot name="navBreadcrumb"></slot>
    </template>
    <template v-if="hasSlot('header')" #header>
      <slot name="header"></slot>
    </template>
    <template v-if="hasSlot('right')" #right>
      <slot name="right"></slot>
    </template>
    <template v-if="hasSlot('main')" #main>
      <slot name="main"></slot>
    </template>
  </HolyGrail>
</template>

<script setup lang="ts">
import { useSlots } from 'vue';

const slots = useSlots();
const hasSlot = name => !!slots[name];
import { HolyGrail } from '@nwire/amit-design-system';

const props = defineProps({
  stretch: {
    type: Boolean,
    default: false,
  },
  maxWidth: {
    type: Boolean,
    default: true,
  },
  // margins: {
  //   type: Boolean,
  //   default: true,
  // },
  flip: {
    type: Boolean,
    default: false,
  },
  marginBottom: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  offset: {
    type: String,
    default: 'center',
  },
  breadcrumb: {
    type: Boolean,
    default: false,
  },
  mainScrollbar: {
    type: Object,
    default: null,
  },
});
</script>
